<div class="content">
  <img src="./assets/img/convalescent.jpg" class="pic" alt="The Convalescent">
  <div class="title">
    The Botanica Story
  </div>
  <p>
    I bought my Wallingford house in 1976 and started gardening before I moved in. Initially I faced a mainstream lawn with a few fruit trees and mal-chosen shrubs, but the last scraps of grass hit the compost pile years ago. My little domain grew into a fine example of competing drives, i.e. the urge to grow every plant known to man vs. the desire to create a coherent esthetic.
  </p>
  <p>
    Commercial growing finally gave me the excuse I needed to compile absurdly large seed orders. I grow both classics and unusual or rare plants, primarily by seed but also by cuttings or divisions. My favorite genera include Arisaema, Cyclamen, Dierama, Gentiana, Helleborus, Paeonia, Tricyrtis, and Trillium, but the list of beloved plants I simply must propagate goes on and on. I swoon for blue flowers and silver foliage. I always offer drought-tolerant plants, Northwest natives, shade plants and flowers that attract birds or butterflies. I avoid the market-hyped "must-haves" and seek enduring quality.
  </p>
  <p>
    My horticultural pet peeve is the overbred monster that has lost all its original wild character. The planet is a treasure trove of floral forms, and our gardens should reflect this. It is sad to walk up the street and see only the same few varieties in every yard. For better or worse, our gardens are our main contact with nature in the city. I hope to help enrich this experience by bringing people more diverse choices.
  </p>
  <p>
    Pamela Harlow
  </p>
  <img src="./assets/img/bar.gif" class="bar" alt="Bar">
</div>

<style lang="scss">
  @import "../styles/_custom-variables.scss";

  .content {
    font-family: 'Arrus-BT-Bold', 'Times New Roman', Times, serif;
    font-size: 0.9rem;
    margin: 2rem;

    .title {
      font-size: 1.1rem;
      color: $main-color;
      margin-bottom: 1rem;
    }

    .pic {
      display: block;
      float: right;
      width: clamp(125px, 50vw, 400px);
      height: auto;
      margin-left: 1em;
    }

    p {
      margin-bottom: 1rem;
    }

    .bar {
      display: block;
      margin: 1em auto;
      max-width: 100%;
      height: auto;
    }

  }
</style>